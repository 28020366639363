import { showAppCue } from 'components/app-cues/actions'
import { APP_CUE_TYPE } from 'components/app-cues/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as GA from 'google-analytics'
import { isEmpty } from 'lodash'
import { EmailTemplates } from 'shared_server_client/constants'
import * as Constants from '../constants'

export const isValidSourceName = (existingSources: any[], sourceName: string): boolean => {
  if (!!existingSources.length && sourceName !== undefined) {
    return !existingSources.filter((es) => es.name.toLowerCase().trim() === sourceName.toLowerCase().trim()).length
  } else {
    return true
  }
}

export const isValidSectionName = (existingSections: any[], sectionName: string, sectionId: number = 0): boolean => {
  if (!!existingSections.length && !isEmpty(sectionName)) {
    return existingSections.filter((es) => es.id !== sectionId &&
    es.name.toLowerCase().trim() === sectionName.toLowerCase().trim()).length !== 0
  } else {
    return false
  }
}

export const isValidUpdatedSourceName = (existingSources: any[], sourceName: string, id: string): boolean => {
  if (!!existingSources.length && sourceName !== undefined) {
  return !existingSources
    .filter((es) => es.name.toLowerCase().trim() === sourceName.toLowerCase().trim() && es.id !== Number(id)).length
  } else {
    return true
  }
}

export const imageIsTooSmall = (imageHeight: any, imageWidth: any, emailTemplate?: any): boolean => {
  if (emailTemplate === EmailTemplates.newspaper) {
    return (imageHeight && imageHeight < Constants.NEWSPAPER_TEMPLATE_MIN_IMAGE_HEIGHT) ||
        (imageWidth && imageWidth < Constants.NEWSPAPER_TEMPLATE_MIN_IMAGE_WIDTH)
  } else if (emailTemplate === EmailTemplates.bigAndBold) {
    return (imageHeight && imageHeight < Constants.BIG_AND_BOLD_TEMPLATE_MIN_IMAGE_HEIGHT) ||
        (imageWidth && imageWidth < Constants.BIG_AND_BOLD_TEMPLATE_MIN_IMAGE_WIDTH)
  } else if (emailTemplate === EmailTemplates.insights) {
    return (imageHeight && imageHeight < Constants.INSIGHTS_TEMPLATE_MIN_IMAGE_HEIGHT) ||
        (imageWidth && imageWidth < Constants.INSIGHTS_TEMPLATE_MIN_IMAGE_WIDTH)
  } else if (emailTemplate === EmailTemplates.default) {
    return (imageHeight && imageHeight < Constants.CLASSIC_TEMPLATE_MIN_IMAGE_HEIGHT) ||
        (imageWidth && imageWidth < Constants.CLASSIC_TEMPLATE_MIN_IMAGE_WIDTH)
  } else {
    return (imageHeight && imageHeight < Constants.BIG_AND_BOLD_TEMPLATE_MIN_IMAGE_HEIGHT) ||
        (imageWidth && imageWidth < Constants.BIG_AND_BOLD_TEMPLATE_MIN_IMAGE_WIDTH)
  }
}

export const postAddSource = (context, contentType: string, identifier: string, push: any) => {
  // eslint-disable-next-line no-console
  console.log('Calling Source Added Appcues')
  context.store.dispatch(showAppCue(APP_CUE_TYPE.SOURCE_ADDED))

  const event: GA.Event4 = {
    name: GA.EventNames.AddSource,
    payload: {
      action_type: contentType,
      identifier,
    },
  }

  context.store.dispatch(GA.sendGa4Event(event))
  context.user.init(true).then(() => {
    push(`${DashboardMenuOption.content}/sources`)
  })
}
